import { render, staticRenderFns } from "./pay-info.vue?vue&type=template&id=710ca226&scoped=true&"
import script from "./pay-info.vue?vue&type=script&lang=js&"
export * from "./pay-info.vue?vue&type=script&lang=js&"
import style0 from "./pay-info.vue?vue&type=style&index=0&id=710ca226&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "710ca226",
  null
  
)

export default component.exports