<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <a-row :gutter="24">
        <!-- 部门树 -->
        <a-col :span="4">
          <dept-tree ref="deptTree" :deptOptions="deptOptions" @select="clickDeptNode" />
        </a-col>
        <a-col :span="20">
          <!-- 条件搜索 -->
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="48">
                <a-col :md="6" :sm="24">
                  <a-form-item label="设备编号">
                    <a-input v-model="queryParam.devCode" placeholder="请输入" allow-clear/>
                  </a-form-item>
                </a-col>
                <a-col :md="6" :sm="24">
                  <a-form-item label="水表编号">
                    <a-input v-model="queryParam.devSn" placeholder="请输入" allow-clear/>
                  </a-form-item>
                </a-col>
                <a-col :md="6" :sm="24">
                  <a-form-item label="订单号">
                    <a-input v-model="queryParam.orderNo" placeholder="请输入" allow-clear/>
                  </a-form-item>
                </a-col>
                <a-col :md="6" :sm="24">
                  <a-form-item label="用户手机号">
                    <a-input v-model="queryParam.userPhone" placeholder="请输入" allow-clear/>
                  </a-form-item>
                </a-col>
                <a-col :md="6" :sm="24">
                  <a-form-item label="订单状态">
                    <a-select v-model="queryParam.orderStatus" show-search placeholder="请选择">
                      <a-select-option v-for="(item,key) in [{name:'待支付',id:0},{name:'充值成功',id:1},{name:'订单取消',id:2},{name:'已退款',id:3},{name:'申请退款中',id:4}]" :key="key" :value="item.id">{{ item.name }}</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :md="8" :sm="24">
                  <a-form-item label="日期范围">
                    <a-range-picker style="width: 100%" v-model="dateRange" valueFormat="YYYY-MM-DD" format="YYYY-MM-DD" allow-clear/>
                  </a-form-item>
                </a-col>
                <a-col :md="!advanced && 4 || 24" :sm="24">
                  <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                    <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                    <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                  </span>
                </a-col>
              </a-row>
            </a-form>
          </div>
          <!-- 操作 -->
          <div class="table-operations">
            <!-- <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['global:team:add']">
              <a-icon type="plus" />新增
            </a-button>
            <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['global:team:edit']">
              <a-icon type="edit" />修改
            </a-button>
            <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['global:team:remove']">
              <a-icon type="delete" />删除
            </a-button> -->
            <a-button type="primary" @click="handleExport" v-hasPermi="['wisdom:order:export']">
              <a-icon type="download" />导出
            </a-button>
            <!-- <a-button type="dashed" :loading="refreshing" @click="handleRefreshCache" v-hasPermi="['global:team:remove']">
              <a-icon type="redo" />刷新缓存
            </a-button> -->
            <table-setting
              :style="{float: 'right'}"
              :table-size.sync="tableSize"
              v-model="columns"
              :refresh-loading="loading"
              @refresh="getList" />
          </div>
          <!-- 详情 -->
          <pay-info
            ref="payInfo"
            :typeOptions="dict.type['sys_yes_no']"
            @ok="getList"
          />
          <!-- 数据展示 -->
          <a-table
            :components="drag(columns)"
            :loading="loading"
            :size="tableSize"
            rowKey="id"
            :columns="columns"
            :data-source="list"
            :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
            :pagination="false"
            :scroll="{ x: 2000 }"
            :bordered="tableBordered">
            <span slot="orderPayState" slot-scope="text, record">
              <a-tag color="orange" v-if="record.orderPayState == 0">待支付</a-tag>
              <a-tag color="green" v-else-if="record.orderPayState == 1">已支付</a-tag>
            </span>
            <span slot="orderStatus" slot-scope="text, record">
              <a-tag color="orange" v-if="record.orderStatus == 0">待支付</a-tag>
              <a-tag color="green" v-if="record.orderStatus == 1">充值成功</a-tag>
              <a-tag color="blue" v-else-if="record.orderStatus == 2">订单取消</a-tag>
              <a-tag color="red" v-else-if="record.orderStatus == 3">已退款</a-tag>
              <a-tag color="cyan" v-else-if="record.orderStatus == 4">申请退款中</a-tag>
            </span>
            <span slot="userInfo" slot-scope="text, record">
              <div class="spanBox">
                <span>{{ record.userIdName }}</span>
                <span>{{ record.czUserPhone }}</span>
              </div>
            </span>
            <span slot="bduserInfo" slot-scope="text, record">
              <div class="spanBox">
                <span>{{ record.userName }}</span>
                <span>{{ record.userPhone }}</span>
              </div>
            </span>
            <span slot="payTime" slot-scope="text, record">
              {{ parseTime(record.payTime) }}
            </span>
            <span slot="createTime" slot-scope="text, record">
              {{ parseTime(record.createTime) }}
            </span>
            <span slot="operation" slot-scope="text, record">
              <a @click="$refs.payInfo.info(record.id)" v-hasPermi="['wisdom:order:query']">
                <a-icon type="eye" />详情
              </a>
            </span>
          </a-table>
          <!-- 分页 -->
          <a-pagination
            class="ant-table-pagination"
            show-size-changer
            show-quick-jumper
            :current="queryParam.pageNum"
            :total="total"
            :page-size="queryParam.pageSize"
            :showTotal="total => `共 ${total} 条`"
            @showSizeChange="onShowSizeChange"
            @change="changeSize"
          />
        </a-col>
      </a-row></a-card>
  </page-header-wrapper>
</template>

<script>

import { orderPage, orderDel } from '@/api/pages/payment-refund'
import PayInfo from './modules/pay-info'
import { tableMixin } from '@/store/table-mixin'
import tableDragResize from '@/utils/tableDragResize'
import { areaList } from '@/api/pages/region'
import DeptTree from '@/components/DeptTree/DeptTree'

export default {
  name: 'Config',
  components: {
    PayInfo, DeptTree
  },
  mixins: [tableMixin, tableDragResize],
  dicts: ['sys_yes_no'],
  data () {
    return {
      // 部门树选项
      deptOptions: [{
        id: 0,
        label: '',
        children: []
      }],
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      refreshing: false,
      total: 0,
      // 日期范围
      dateRange: [],
      queryParam: {
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '订单号',
          dataIndex: 'orderNo',
          fixed: 'left',
          ellipsis: true,
          width: 160,
          align: 'center'
        },
        {
          title: '水表编号',
          width: 160,
          dataIndex: 'devSn',
          fixed: 'left',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '三方流水号',
          dataIndex: 'orderPayNo',
          ellipsis: true,
          width: 250,
          align: 'center'
        },
        {
          title: '用户信息',
          dataIndex: 'userInfo',
          scopedSlots: { customRender: 'userInfo' },
          ellipsis: true,
          width: 120,
          align: 'center'
        },
        {
          title: '设备名称',
          dataIndex: 'devName',
          ellipsis: true,
          width: 160,
          align: 'center'
        },
        {
          title: '设备编号',
          width: 160,
          dataIndex: 'devCode',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '绑定人信息',
          dataIndex: 'bduserInfo',
          scopedSlots: { customRender: 'bduserInfo' },
          ellipsis: true,
          width: 120,
          align: 'center'
        },
        // {
        //   title: '优惠金额',
        //   width: 100,
        //   dataIndex: 'orderDiscountMoney',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '总金额',
          width: 100,
          dataIndex: 'orderMoney',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '支付状态',
          width: 100,
          dataIndex: 'orderPayState',
          scopedSlots: { customRender: 'orderPayState' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '订单状态',
          width: 100,
          dataIndex: 'orderStatus',
          scopedSlots: { customRender: 'orderStatus' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '支付时间',
          width: 160,
          dataIndex: 'payTime',
          scopedSlots: { customRender: 'payTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '创建时间',
          width: 160,
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          width: 100,
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
          fixed: 'right',
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getDeptTree()
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询区域下拉树结构 */
    getDeptTree() {
      areaList().then(response => {
        this.deptOptions = response.data
      })
    },
    clickDeptNode(id, e) {
      console.log(id, e.selectedNodes[0].data.props)
      const label = e.selectedNodes[0].data.props.label
      const level = e.selectedNodes[0].data.props.level
      if (level < 4) {
        this.queryParam.communityId = null
        this.queryParam.buildingId = null
        this.queryParam.pcaName = label
      } else if (level === 4) {
        this.queryParam.pcaName = null
        this.queryParam.buildingId = null
        this.queryParam.communityId = id
      } else if (level === 5) {
        this.queryParam.pcaName = null
        this.queryParam.communityId = null
        this.queryParam.buildingId = id
      }
      this.handleQuery()
    },
    /** 查询参数列表 */
    getList () {
      if (this.dateRange !== null && this.dateRange !== '' && this.dateRange.length === 2) {
        this.queryParam.createBeginTime = this.dateRange[0] + ' 00:00:00'
        this.queryParam.createEndTime = this.dateRange[1] + ' 23:59:59'
      }
      this.loading = true
      orderPage(this.queryParam).then(response => {
          this.list = response.data.records
          this.total = response.data.total
          this.loading = false
        }
      )
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.dateRange = []
      this.queryParam = {
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const configIds = row.id ? [row.id] : this.ids
      this.$confirm({
        title: '提示',
        content: '确认删除所选中数据?',
        onOk () {
          return orderDel(configIds)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {}
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('wisdom/wisdom-order/export', {
            ...that.queryParam
          }, `水充值订单_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>

<style scoped>
.spanBox{
  display: flex;
  flex-direction: column;
}
</style>
